import React from "react";
import {
  ChevronDoubleRightIcon,
  ClockIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { useContext } from "react";

export default function Header() {
  const { t } = useTranslation();
  const { language, languages, originalPath } = useContext(I18nextContext);
  const [srCyrl, srLatn] = languages;

  return (
    <header className="relative flex flex-col items-center justify-center h-screen ">
      <Link
        to={language === srCyrl ? `/${srLatn}` : "/"}
        language={language === srCyrl ? srLatn : srCyrl}
        className="absolute z-10 flex items-center space-x-2 text-xl text-red-500 top-3 right-3"
      >
        <SwitchHorizontalIcon className="w-5 h-5" />
        <span>{t("change-locale")}</span>
      </Link>
      <div className="absolute inset-0 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src="/images/bg2.png"
          alt=""
        />

        <div
          className="absolute inset-0 bg-gray-800 mix-blend-color"
          aria-hidden="true"
        />
      </div>
      <div className="relative text-center">
        <div className="max-w-[200px] md:max-w-[450px] mb-6 mx-auto">
          <img
            src="/images/symbol.svg"
            alt=""
            className="w-full"
            width="563px"
            height="241px"
          />
        </div>

        <div className="max-w-[320px] md:max-w-[450px] mb-1 md:mb-2 overflow-hidden mx-auto">
          <img
            src="/images/whois.svg"
            alt=""
            className="w-full"
            width="563px"
            height="91px"
          />
        </div>

        <div className="flex items-center justify-center mb-6 space-x-2 text-2xl font-bold tracking-wide text-white">
          <ClockIcon className="w-5 h-5" />
          <span>{t("header-date")}</span>
        </div>
      </div>
    </header>
  );
}
